<template>
  <router-view/>
</template>
<script>
export default {
  name: "PageIndex",
  mounted() {
    // Redirect to 1 page if there is no page number
    this.redirectList();
  },
  updated() {
    //Redirect to 1 page if there is no page number
    this.redirectList();
  },
  methods: {
    //Redirect to 1 page if there is no page number
    redirectList() {
      if (this.$route.name == 'tour') {
        this.$router.replace({ name: "tourSeries" });
      }
    },
  },
};
</script>
